export const constants = {
	shortcuts: {
		65  : 'add',
		68  : 'delete',
		70  : 'add folder',
		77  : 'edit mode',
		73  : 'import',
		69  : 'export',
		83  : 'save',
		72  : 'help',
		82  : 'revert',
		81  : 'quit',
		79  : 'other',
		80  : 'placemarks',
		219 : 'other placemarks',
		67  : 'center',
		27  : 'close',
		37  : 'left',
		39  : 'right',
		90  : 'undo',
		89  : 'redo',
	},
	dirs: {
		uploads: {
			images: {
				big: '/uploads/images/big/',
				small: '/uploads/images/small/',
				orphanedbig: '/uploads/images/big/orphaned/',
				orphanedsmall: '/uploads/images/small/orphaned/',
			},
		},
	},
	sessionlifetime: 3600,
	backupscount: 10,
	commonplacesonpagecount: 5,
	map: {
		initial: {
			latitude  : 0,
			longitude : 0,
			zoom      : 15,
		},
	},
	sidebars: {
		top    : 100,
		right  : 250,
		bottom : 54,
		left   : 250,
	},
	compactWidth: 850,
};
